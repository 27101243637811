import { Calendar, EventProps, momentLocalizer, View, ToolbarProps, Views } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Grid } from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { BorderBottom, BorderColor, NavigateNextTwoTone } from "@mui/icons-material";
import moment from "moment";
import './eventStyles.css'
import  dateFns, { getDate, toDate }  from "date-fns";

import 'moment-timezone';
import { title } from "process";
//const localizer = luxonLocalizer(DateTime);
const localizer = momentLocalizer(moment);


interface MyEvent  {
    id: number;
    title: string;
    start: Date;
    end: Date;

    link: string;
    backgroundColor: string;
    textColor: string;
    select: string;
    secondSelect: string;
    link2: string;
    borderColor: string

}
const events: MyEvent[] = [
  {
    id: 0,
    select: 'Click Here To Register',
    secondSelect: 'Click To Access Flyer',
    title: "The Power of Externships - Presenter: Glenn Kalick, DVM & Walter Brown, RVTg, VTS (ECC) Track: Leadership",

   //start: moment().toDate(),
    start: new Date(2024,5,19, 13, 0),
    end: new Date(2024,5,19, 14, 0),

    link: "https://events.teams.microsoft.com/event/ad959a87-1219-4cd9-828d-337635b02e68@ce76298f-68d5-4b4c-8ace-1f905bdd4538",
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: ""
  },
  {
    id: 1,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    title: "Arterial Blood Pressur Monitoring - Why, When, How, and for What? ~ Presenter: Kate Bailey, DVM DACVAA Track: Speciality & VTS",
   //start: moment().toDate(),
    start: new Date(2024,5,26, 13, 0),
    end: new Date(2024,5,26, 14, 0),

    link: "https://events.teams.microsoft.com/event/b6dd0e20-3b02-491a-9caf-4709fccbaf0e@ce76298f-68d5-4b4c-8ace-1f905bdd4538",
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " "
  },

{
    id: 2,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.08.24%20webinar%20flyer%201.pdf?csf=1&web=1&e=QSPNWE",
title: "Demystifying Compliance with Federal Controlled Substance Laws~ Presenter: Kim Heisel, RTVg, CVPM, FFCP Track: All Team",
start: new Date(2024,6,8,13,0),
end: new Date(2024,6,8,14,0),
link: "https://events.teams.microsoft.com/event/c73faa0a-892f-4fc3-820f-a8ad6cd37ca0@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 3,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " ",
title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC) Track: All Team",
start: new Date(2024,6,9,19,0),
end: new Date(2024,6,9,20,0),
link: "https://events.teams.microsoft.com/event/6b9d956b-fd1f-4a13-8ac0-9ac42caffa41@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
id: 4,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.10.24%20webinar%20flyer.pdf?csf=1&web=1&e=HjJ5JX",
title: "Cultivating and Leading a Diverse, Equitable, and Inclusive Culture in Veterinary Medicine ~ Presenter: Niccole Bruno, DVM Track: All Team",
start: new Date(2024,6,10,13,0),
end: new Date(2024,6,10,14,0),
link: "https://events.teams.microsoft.com/event/5c9fc6b8-69eb-4630-8e25-cd7fca63dc83@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
id: 5,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.16.24%20webinar%20flyer.pdf?csf=1&web=1&e=RB887W",
title: "Encore: Demystifying Compliance with Federal Controlled Substance Laws ~ Presenter: Kim Heisel, RTVg, CVPM, FFCP Track: All Team",
start: new Date(2024,6,16,13,0),
end:  new Date(2024,6,16,14,0),
link: "https://events.teams.microsoft.com/event/453dfa10-5931-4747-94cb-aeb722dee98e@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
id: 6,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.17.24%20webinar%20flyer.pdf?csf=1&web=1&e=cA9YSR",
title: "Responding to Client Complaints and PLIT ~ Presenter: Laura Garlow, DVM & Stephanie Thomas Track: DVM",
start:  new Date(2024,6,17,13,0),
end:    new Date(2024,6,17,14,0) ,
link: "https://events.teams.microsoft.com/event/8b3a632b-abf4-48f7-a0b0-8aa6344a582a@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
id: 7,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.24.24%20webinar%20flyer.pdf?csf=1&web=1&e=glcGzy ",
title: "Putting the Puzzle Pieces Together: Critical Thinking Case Study ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) Track: VTS" ,
start: new Date(2024,6,24,13,0),
end:   new Date(2024,6,24,14,0),
link: "https://events.teams.microsoft.com/event/9a768032-e3e0-4eb7-a817-c9f69ccaa0f3@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
id: 8,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/07.31.24%20webinar%20flyer.pdf?csf=1&web=1&e=nD8Hi0",
title: "“Have You Seen My Ball?” A Dive into Cognitive Dysfunction Syndrome ~ Presenter: Katie Retzlaff, RVT Track: Tech/Assist",
start:   new Date(2024,6,31,13,0),
end:      new Date(2024,6,31,14,0),
link: "https://events.teams.microsoft.com/event/34028f57-ff99-431c-9517-31d08a128537@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 9,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/08.07.24%20webinar%20flyer.pdf?csf=1&web=1&e=oz6NMU",
title: "Common ER Toxicities and Principles ~ Presenter: Jeffrey Backus, CVT, RVT, VTS (ECC) Track: VTS",
start:    new Date(2024,7,7,13,0)  ,
end:      new Date(2024,7,7,14,0)     ,
link: "https://events.teams.microsoft.com/event/4b4868cd-0622-47eb-a521-90977f776161@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

},
{
    id: 10,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/08.12.24%20webinar%20flyer.pdf?csf=1&web=1&e=ZAUYuf",
title: "Best Practices for Controlled Substance Management ~ Presenter: Kim Heisel, RTVg, CVPM, FFCP Track: All Team",
start:     new Date(2024,7,12,13,0)   ,
end:      new Date(2024,7,12,14,0)  ,
link: "https://events.teams.microsoft.com/event/df304992-a795-49af-a587-95bb228388e5@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 11,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " ",
title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC) Track: All Team" ,
start:    new Date(2024,7,13,19,0)      ,
end:      new Date(2024,7,13,20,0)     ,
link: "https://events.teams.microsoft.com/event/c23c6ebf-4adf-4564-b44f-eeb037fc0f2b@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
    id: 12,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/08.14.24%20webinar%20flyer.pdf?csf=1&web=1&e=WdNtB3 ",title: "Early Career Wellbeing Solutions ~ Presenter: Addie Reinhard, DVM, MS Track: All Team",
start:    new Date(2024,7,14,13,0)    ,
end:     new Date(2024,7,14,14,0)       ,
link: "https://events.teams.microsoft.com/event/0f6a1559-c379-4cdf-875a-c59f4efa9a07@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
    id: 13,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/08.21.24%20webinar%20flyer.pdf?csf=1&web=1&e=ckoA5y",
title: "Intro to Exotics ~ Presenter: Lorelei D’Avolio LVT, CVT, VTS (CP-Exotics), CVPM Track: Tech/Assist",
start:   new Date(2024,7,21,13,0)     ,
end:   new Date(2024,7,21,14,0)  ,
link: "https://events.teams.microsoft.com/event/3e716bca-dc06-40de-aac5-87ce91e33b87@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

},

{
    id: 14,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/08.28.24%20webinar%20flyer.pdf?csf=1&web=1&e=Md2qfb ",
title: "Beyond the Touchy-Feely: The Science and Strategy of Emotional Intelligence ~ Presenter: Alessandra LaTour, Ed.D., CVT, LAT Track: Leadership",
start:   new Date(2024,7,28,13,0)   ,
end:   new Date(2024,7,28,14,0)      ,
link: "https://events.teams.microsoft.com/event/08a222eb-8c79-4191-aeef-1efc8e034f48@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{id: 15,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/09.04.24%20webinar%20flyer.pdf?csf=1&web=1&e=vPj1ok",
    title: "When Deep Pockets are a Bad Thing: Periodontal Disease Diagnosis, Treatment, & Prevention ~ Presenter: Laura Leinen, DVM Track: Tech/Assist",
start:   new Date(2024,8,4,13,0)       ,
end:    new Date(2024,8,4,14,0)      ,
link: "https://events.teams.microsoft.com/event/4fa49fdd-3284-48fc-ac90-dcdeb2105e78@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
}
,
{
    id: 16,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/09.09.24%20webinar%20flyer.pdf?csf=1&web=1&e=Xqdfs9 ",
title: "When Controlled Substance Management Goes Awry ~ Presenter: Kim Heisel, RTVg, CVPM, FFCP Track: All Team",
start:   new Date(2024,8,9,13,0)        ,
end:  new Date(2024,8,9,14,0)          ,
link: "https://events.teams.microsoft.com/event/7878a2a2-79a9-4434-85e4-5234a15e6996@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{id: 17,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " ",
    title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC) Track: All Team" ,
start:  new Date(2024,8,10,19,0)          ,
end:     new Date(2024,8,10,20,0)       ,
link: "https://events.teams.microsoft.com/event/e0ba50f1-8a8f-40e2-9a3b-4d8f383e6374@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{id: 18,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/09.11.24%20webinar%20flyer.pdf?csf=1&web=1&e=wwix1Z",
    title: "Pain Management From start to Finish ~ Presenter: Kate Bailey, DVM, DACVAA  Track: DVM",
start:  new Date(2024,8,11,13,0)           ,
end:    new Date(2024,8,11,14,0)        ,
link: "https://events.teams.microsoft.com/event/a2110783-2e57-455e-9eef-7331f0557efd@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 19,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/09.18.24%20webinar%20flyer.pdf?csf=1&web=1&e=J4OhhB ",
title: "Communicating to Maximize Client Compliance and Quality Patient Care ~ Presenter: Laura Garlow, DVM  Track: Leadership",
start:  new Date(2024,8,18,13,0)           ,
end:    new Date(2024,8,18,14,0)           ,
link: "https://events.teams.microsoft.com/event/f4ff666f-d1bf-404a-9eb2-1655a9626347@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 20,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/09.25.24%20webinar%20flyer.pdf?csf=1&web=1&e=nneMqX ",
title: "Fluids are Drugs ~ Presenter: Melissa Evans, CVT, LVT, VTS (ECC) Track: VTS",
start:  new Date(2024,8,25,13,0)          ,
end:     new Date(2024,8,25,14,0)        ,
link: "https://events.teams.microsoft.com/event/521c472d-7da2-40c4-8acb-c1e22080ded7@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{id: 21,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/10.02.24%20webinar%20flyer.pdf?csf=1&web=1&e=7yiCGh ",
    title: "M and M Rounds Done Right ~ Presenter: Kate Bailey, DVM, DACVAA Track: All Team",
start:  new Date(2024,9,2,13,0)            ,
end:     new Date(2024,9,2,14,0)              ,
link: "https://events.teams.microsoft.com/event/822bdefd-3c67-4268-ba2b-a3d35e9d10f6@ce76298f-68d5-4b4c-8ace-1f905bdd4538"
},

{id: 22,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: " ",
    title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC) Track: All Team" ,
start:   new Date(2024,9,8,19,0)              ,
end:     new Date(2024,9,8,20,0)               ,
link: "https://events.teams.microsoft.com/event/55478be7-f066-4431-bb9d-a101a8185483@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{id: 23,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/10.09.24%20webinar%20flyer.pdf?csf=1&web=1&e=n4swdK ",
    title: "When Quality of Life Scales Aren’t Enough: Counseling Clients Who Can’t Let Go ~ Presenter: Kathleen Cooney, DVM, MS, CHPV, CCFP, DACAW resident Track: DVM",
start:  new Date(2024,9,9,13,0)                 ,
end:    new Date(2024,9,9,14,0)                ,
link: "https://events.teams.microsoft.com/event/8164ed64-d02f-418b-85bb-0bda37a9ce21@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
    id: 24,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " ",title: "Pharmacology to Support Fear Free ~ Presenter: TBA Track: All Team",
start:    new Date(2024,9,16,13,0)            ,
end:     new Date(2024,9,16,14,0)              ,
link: "https://events.teams.microsoft.com/event/d8a0354f-a15e-424e-935f-6a7650a6082f@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{id: 25,
    select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
    backgroundColor: "#fff", borderColor: "#FF9800",
    textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/10.23.24%20webinar%20flyer.pdf?csf=1&web=1&e=njHtVX ",
    title: "The Heart and How We Help It ~ Presenter: Jamie Schultz, CVT, VTS (ECC) Track: VTS",
start:    new Date(2024,9,23,13,0)              ,
end:    new Date(2024,9,23,14,0)              ,
link: "https://events.teams.microsoft.com/event/24d53180-4840-4c56-8a60-741e6c00bcb9@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 26,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/10.30.24%20webinar%20flyer.pdf?csf=1&web=1&e=ykOVza ",
title: "Leadership for the Tik Tok Generation ~ Presenter: Peter Weinstein, DVM, MBA Track: Leadership ",
start:   new Date(2024,9,30,13,0)               ,
end:    new Date(2024,9,30,14,0)                ,
link: "https://events.teams.microsoft.com/event/609611d4-cbdb-4350-b5cd-bef33cee26dc@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

},
{
    id: 27,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/11.06.24%20webinar%20flyer.pdf?csf=1&web=1&e=7KhxKu",title: "Anesthesia in Cardiac Patients ~ Presenter: Kate Bailey, DVM, DACVAA  Track: All Team" ,
start:    new Date(2024,10,6,13,0)               ,
end:      new Date(2024,10,6,14,0)              ,
link: "https://events.teams.microsoft.com/event/cd1f6e51-ba7b-4416-bc9b-de5fd7716ba8@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 28,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " ",
title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC)\ Track: All Team" ,
start:    new Date(2024,10,12,19,0)                ,
end:      new Date(2024,10,12,20,0)           ,
link: "https://events.teams.microsoft.com/event/eefcb64b-b390-49e2-b6b8-ae660812912c@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

},
{
    id: 29,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/11.13.24%20webinar%20flyer.pdf?csf=1&web=1&e=888hgi ",
title: "Venous Access and Euthanasia Basics for Exotics ~ Presenter: Molly Gleeson, DVM, DACZM and Kyra Berg, DVM, DACZM Track: DVM" ,
start:    new Date(2024,10,13,13,0)                ,
end:    new Date(2024,10,13,14,0)                 ,
link: "https://events.teams.microsoft.com/event/20c0a341-b4d7-4538-85a0-0049b5e5bfb1@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 30,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/11.20.24%20webinar%20flyer.pdf?csf=1&web=1&e=4B36HL ",
title: "Avian Anesthesia  ~ Presenter: Lorelei D’Avolio LVT, CVT, VTS (CP-Exotics), CVPM Track: DVM" ,
start:   new Date(2024,10,20,13,0)                  ,
end:     new Date(2024,10,20,14,0)                  ,
link: "https://events.teams.microsoft.com/event/00094fc5-f5d3-4f1d-814b-c7b582340138@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},
{
    id: 31,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/12.04.24%20webinar%20flyer.pdf?csf=1&web=1&e=Ioxc4u",
title: "My Patient Has a Corneal Opacity, What is it? ~ Presenter: LaTisha Nicole Knight, DVM, MS, DACVO Track: DVM" ,
start:   new Date(2024,11,4,13,0)                 ,
end:    new Date(2024,11,4,14,0)                   ,
link: "https://events.teams.microsoft.com/event/7ba8361d-b1b4-4374-ae98-e1b461c07469@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

},

{
    id: 32,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " ",
title: "VTNE/Journal Club ~ Presenter: Rachel Kinser MS, RVT, VTS (ECC) & Walter Brown, RVTg, VTS (ECC) Track: All Team ",
start:   new Date(2024,11,10,19,0)                  ,
end:     new Date(2024,11,10,20,0)                  ,
link: "https://events.teams.microsoft.com/event/ef32b744-debd-4aa3-a327-3fd6f97ea3d1@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
    id: 33,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: "https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/12.11.24%20webinar%20flyer.pdf?csf=1&web=1&e=F2cW0a ",
title: "The Million Dollar Technician ~ Presenter: Andy Roark, DVM, MScTrack: Wellbeing" ,
start:  new Date(2024,11,11,13,0)                   ,
end:     new Date(2024,11,11,14,0)             ,
link: "https://events.teams.microsoft.com/event/9cd229ed-d4be-4f57-a40c-203aab56fddd@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "
},

{
    id: 34,
select: 'Click Here To Register', secondSelect: 'Click To Access Flyer',
backgroundColor: "#fff", borderColor: "#FF9800",
textColor: "#063178", link2: " https://unitedvetcare.sharepoint.com/:b:/r/Shared%20Documents/L%26D%20Events/L%26D_webinar_flyers/12.18.24%20webinar%20flyer%20(1).pdf?csf=1&web=1&e=xt5SNt",
title: "Non-Manual Positioning Techniques for ER Trauma Patients~ Presenter: Amy Cardwell, CVT, VTS (DI) Track: Tech/Assist",
start:  new Date(2024,11,18,13,0)                       ,
end:     new Date(2024,11,18,14,0)                     ,
link: "https://events.teams.microsoft.com/event/839d99ee-6eba-4f31-8fe8-f1631201c481@ce76298f-68d5-4b4c-8ace-1f905bdd4538 "

}

];
const CustomToolbar: React.FC<ToolbarProps> = ({label, onNavigate}) => {

    const goBack = () => {
        onNavigate('PREV');
    };
    const goNext = () => {
        onNavigate('NEXT');
    };
    const goToday = () => {
        onNavigate('TODAY');
    };

    const handlelinkClick = () => {
        window.open("https://unitedvetcare.sharepoint.com/:b:/g/EdmTStYf2dZKjIg8R1Qj4VMBTGqqFEevhiEWheD1O4MX6Q?e=3e2lGy", '_blank')
    }

  return (
    <div className="rbc-toolbar" >
      <span className="rbc-btn-group">
        <button type="button" onClick={goBack}>Prev</button>
        <button type="button" onClick={goToday}>Today</button>
        <button type="button" onClick={goNext}>Next</button>
      </span>

      <span className="rbc-toolbar-label">
      <a style={{color: "#063178" }}>
        Virtual Learning Events

     </a>
      <br/>
      <a>{label}</a>
      </span>


      <button
        type="button"
        className="custom-link-button"
        onClick={handlelinkClick}

      >
        Downloadable Calendar
        </button>

    </div>
  );
};



function handleView(event: {}) {
  // do something here for rendering the view
}

function handleEventDoubleClick(event: {
  id: number;
  title: string;
  end: Date;
}) {
  // navigate browser to link
  window.location.href = events.find((e) => e.id === event.id)?.link || "";
}

const Event: React.FC<EventProps<MyEvent>> = ({ event }) => {
  const timezone = 'America/New_York';
  const formattedstart = moment(event.start).tz(timezone).format('h:mm A');
  const formattedend = moment(event.end).tz(timezone).format('h:mm A');

  return (
 <span className="event-text">



         <div>
          <a href={event.link} target="_blank" rel="noopender noreferrer" style={{ color: event.textColor}} >

             <strong className="event-link">{event.title}</strong>
            </a>
            <br />
            <a href={event.link2} target="_blank" rel="noopender noreferrer" style={{ color: "#063178" }}>
                <strong>{event.secondSelect}</strong>

            </a>
            </div>
            <div>{formattedstart}</div>






          </span>






)};
const eventPropGetter = (event: MyEvent) => {
    const backgroundColor = event.backgroundColor;
    const textColor = event.textColor;
    const borderColor = event.borderColor;

 return {
    style: {
        backgroundColor,
        color: textColor,
        border: `2px solid ${borderColor}`
    }};
};

export function LearnCalendar() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="600px"
      height="80%"


      // minWidth="600px"
    >
      <Calendar<MyEvent>
        style={{ width: "80%" }}
        localizer={localizer}
        // view={Views.MONTH}
        // onView={handleView}
        startAccessor="start"
        endAccessor="end"
        components={{
          event: Event,
          toolbar: CustomToolbar
        }}
        eventPropGetter={eventPropGetter}



        events={events}
        onDoubleClickEvent={handleEventDoubleClick}

      />
    </Box>
  );
}
